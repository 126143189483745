console.log('process.env', process.env)
const serverList = [
    {
        BASEURL: 'http://192.168.1.26:8401',
    },
    {
        BASEURL: 'https://gameapi.etuw.cn',
    },
    {
        BASEURL: 'https://tao648.cn/api',
    },
];
let serverArr;
let url = window.location.href;
if (url.startsWith("http://192.168")) {
    serverArr = serverList[0]
    console.log('本次测试环境');
}
else if (url.indexOf('etuw') > -1) {
    serverArr = serverList[1]
    console.log('etuw后台地址');
}
else if (url.indexOf('tao648') > -1) {
    serverArr = serverList[2]
    console.log('tao648后台地址');
}

//serverArr = serverList[1]
export default serverArr
