<template>
    <div class="code-view-main" v-if="url">
        <img :src="url" style="width: 95px;height: 95px;">
        <span style="font-size: 10px;color: #333333;margin-top: 5px;">扫码关注微信公众号</span>
    </div>
</template>

<script>
    import {request} from "@/api/request";

    export default {
        name: "GzhCodeView",
        props: {
            weChatUrl: {
              type: String,
              default: ''
            }          
        },
        data(){
          return {
            url: ''
          }
        },
        mounted() {
          this.queryWebConfig();
        },
        methods: {
          queryWebConfig() {
            window.addEventListener('beforeunload', () => localStorage.removeItem('config'));
            let config = JSON.parse(localStorage.getItem('config'));
            if(config){
              this.url = config.wechatUrl;
              return;
            }

            request({
              url:"/open/websiteConfig",
              method: 'post'
            }).then(res => {
              this.url = res.data.data.wechatUrl;
              localStorage.setItem('config', JSON.stringify(this.webConfig))
              localStorage.setItem('web_name', res.data.data.appName)
              localStorage.setItem('logo_url', res.data.data.logoUrl)
            })
          },
        }
    }
</script>

<style scoped>
    .code-view-main {
        position: fixed;
        right: 30px;
        top: 45%;
        padding: 10px;
        background-color: #ffffff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 999;
    }
</style>