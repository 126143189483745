<template>
    <div class="main-container">
        <div class="dialog-title">
            <img  @click="back" src="../assets/back.png" style="width: 25px;height: 25px;">
            <div style="margin-left: 5px;">微信绑定</div>
        </div>

        <div class="main" v-show="loadFinished" style="height: 260px;overflow-y: auto;">
            <div v-if="isBindWx" style="text-align: left;color: #cccccc;font-size: 13px;padding: 0 25px;">

                <div class="red-tips-views" style="display: flex;flex-direction: column">
                    <p style="color: #dddddd;font-size: 16px;">当前帐号已绑定微信</p>
                </div>

                <div style="display: flex;align-items: center;margin-top: 15px;">
                    <img :src="headImg" style="width: 50px;height: 50px;border-radius: 3px;">
                    <div style="display: flex;flex-direction: column;margin-left: 10px;">
                        <span style="font-size: 14px;font-weight: bold; color: #eeeeee;padding: 4px 15px;">{{code}}</span>
                        <span style="font-size: 12px;color: #bbbbbb;padding: 4px 15px;">{{bindTime}}</span>
                    </div>

                </div>

                <div style="margin-top: 15px" v-if="!showCodeForUnbindWx">

                    <div class="box-button">
                        <van-button type="info" block native-type="submit" size="small"  :loading="loading" round
                                    style="width: 160px;margin-top: 20px;"
                                    loading-text="解绑中"  @click="unbindWx"
                                    color="#e73d50">
                            解绑
                        </van-button>
                    </div>
                </div>

                <div v-else style="margin-top: 20px;color: #888888">
                    <span>本次操作需要验证身份，验证码已发送至该帐号绑定的手机</span>
                    <div style="display: flex;align-items: center;justify-content: flex-start; margin-top: 20px">
                        <div class="input-view" style="margin-top: 0;width: 130px;">
                            <van-field type="digit" maxlength="6" placeholder="请输入验证码"  v-model="unbindCode" />
                        </div>

                        <van-button type="info" block native-type="submit" size="small"  :loading="loading" round
                                    style="width: 110px;margin-left: 15px;"
                                    loading-text="提交中"  @click="unbindWxWithCode"
                                    color="#e73d50">
                            确认解绑
                        </van-button>
                    </div>
                </div>
            </div>

            <div v-else style="text-align: left;color: #cccccc;font-size: 13px;padding: 0 15px;">
                <div class="green-tips-views" style="display: flex;flex-direction: column">
                    <p style="color: #dddddd;font-size: 16px;">当前帐号未绑定微信</p>
                    <p>参与幸运转盘与红包雨的用户需绑定微信才能领取红包，微信绑定流程:</p>
                    <span>1. 关注公众号：{{ this.webName }}，点击底部菜单：用户中心 - 绑定微信</span>
                    <span>2. 输入需要绑定的帐号和密码</span>
                    <span>2. 点击确认绑定按钮</span>
                </div>

                <div class="box-button">
                    <van-button type="info" block  size="small" :loading="loading" round style="width: 160px;"
                                color="#e73d50" @click="openWx">
                        打开微信
                    </van-button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {request} from '@/api/request'
    import Toast from "vant/lib/toast";
    import {Dialog} from "vant";
    import notice from '@/api/notice'

    export default {
        name: "weixin",
        components: {
        },
        data() {
            return {
                loading: false,
                isBindWx: false,
                code: '',
                bindTime: '',
                headImg: '',
                from: '',
                loadFinished: false,
                unbindWxSendTime: 0,
                showCodeForUnbindWx: false,
                bindPhone: '',
                unbindCode: '',
                webName: '',
            }
        },
        mounted () {
            this.webName = localStorage.getItem('web_name')
            this.from = localStorage.getItem('from');
            this.initData();
        },
        methods: {
            initData() {
                this.loading = true;

                let options = {
                    url:"/h5/bindWx",
                    data: {}
                };

                request(options).then(res => {
                    this.loading = false;
                    if (res.data.success) {
                        this.isBindWx = res.data.data.bindStatus === 2;
                        this.code = res.data.data.bindInfo;
                        this.bindTime = res.data.data.bindTime;
                        this.headImg = res.data.data.headImg;
                        this.loadFinished = true;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            getParent() {
                let from = localStorage.getItem("from");
                if (from === 'android_sdk') {
                    return  this.$parent;
                }
                else {
                    return this.$parent.$parent;
                }
            },
            back() {
                this.getParent().hideBindWeixinPage();
            },
            unbindWx() {
                Dialog.confirm({
                    message: '解绑微信后无法领取福利红包，确认要解绑吗?',
                    messageAlign: 'left'
                })
                .then(() => {
                    this.checkHasBindPhone();
                })
                .catch(() => {
                });
            },
            checkHasBindPhone() {
                let now = new Date().getTime();
                if (this.showCodeForUnbindWx) {
                    let interval = (now - this.unbindWxSendTime) / 1000;
                    if (interval < 60) {
                        Toast('操作太频繁，请在' + (60 - interval) + '秒后重试');
                        return ;
                    }
                }

                this.loading = true;
                let options = {
                    url: "/h5/checkAndSendSms?type=6",
                    method: 'get'
                };
                request(options).then(res => {
                    this.loading = false;
                    if (res.data.success) {
                        let result = res.data.data;
                        if (result === '0') {//未绑定手机
                            this.doUnbindWx();
                        }
                        else if (result === '1') {//已绑定并发送短信
                            this.unbindWxSendTime = now;
                            this.showCodeForUnbindWx = true;
                        }
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            unbindWxWithCode() {
                if (!/^\d{6}$/.test(this.unbindCode)) {
                    Toast("请输入6位数字验证码");
                    return ;
                }
                this.doUnbindWx();
            },
            doUnbindWx() {
                this.loading = true;
                let options = {
                    url:"/h5/unBindWx",
                    data: {
                        checkCode: this.unbindCode
                    }
                };
                request(options).then(res => {
                    this.loading = false;
                    if (res.data.success) {
                        Toast('微信解绑成功');
                        this.isBindWx = false;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            openWx() {
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;
                }, 2000);
                let url = 'weixin://';
                notice.startIntent(url)
            }
        }
    }
</script>

<style scoped>
    .main-container {
        overflow: hidden;
    }

    .dialog-title {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 0 10px;
        width: 100%;
        vertical-align: center;
        font-size: 16px;
        color: #ED9015;
    }

    .box-button {
        margin: 30px 0 10px;
        display: flex;
        justify-content: center;
    }
    .bind-text{
        font-size: 13px;
        color: #666666;
    }

    .van-field{
        border-bottom: .5px solid #707071;
        background-color: #2f2f2f;
        color: #ffffff;
    }

    /deep/.van-field__control {
        color: #ffffff;
    }

    .van-cell {
        padding: 3px 8px;
    }

</style>