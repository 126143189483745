<template>
    <div class="nav-back" style="height: 80px;background-color: #3e4757;width: 100%;">
        <div class="nav-center">
            <div class="logo-view">
                <img :src="logoUrl" style="width: 50px;">
                <span style="margin-left: 20px;font-weight: bold;color: #efefef;font-style: italic;font-size: 25px;">{{ webName }}</span>
            </div>
            <div class="menu-view">
                <div class="menu" :class="index === 1 && 'menu-active'" @click="toIndexPage">
                    首页
                    <div class="active-line" :class="preIndex > 1 ? 'from-right' : 'from-left'"
                         v-show="index === 1"></div>
                </div>
                <div class="menu" :class="index === 2 && 'menu-active'" @click="toGameListPage">
                    所有游戏
                    <div class="active-line" :class="preIndex > 2 ? 'from-right' : 'from-left'"
                         v-show="index === 2"></div>
                </div>
                <div class="menu" :class="index === 3 && 'menu-active'" @click="toFuliPage">
                    福利
                    <div class="active-line" :class="preIndex > 3 ? 'from-right' : 'from-left'"
                         v-show="index === 3"></div>
                </div>
                <div class="menu" :class="index === 4 && 'menu-active'" @click="toKfPage">
                    联系客服
                    <div class="active-line" :class="preIndex > 4 ? 'from-right' : 'from-left'"
                         v-show="index === 4"></div>
                </div>
            </div>
            <div class="search">
                <div class="search-box">
                    <img src="../../assets/search.png" @click="searchGame">
                    <span class="split"></span>
                    <input class="input" v-model="game_name" type="text" @keyup.enter="searchGame">
                    <span class="btn" @click="searchGame">搜索</span>
                </div>
            </div>
            <div class="icon-container" @mouseenter="hoverIn">
                <img src="../../assets/user.png" class="user">

                <div v-if="dialogHover" class="drop-down">
                    <img src="../../assets/default-user.png" class="user-icon">
                    <div style="font-size: 16px;color: #888888;font-weight: bold;margin-top: 20px;">您还未登录
                        <span @click="openLoginPage"
                              style="color: #f16868;margin-left: 15px;cursor: pointer;">立即登录</span>
                    </div>
                </div>

                <div v-if="dialogGame" class="drop-down">
                    <div style="height: 140px;padding-top: 20px;">
                        <img src="../../assets/default-user.png" class="user-icon">
                        <div style="font-size: 16px;color: #666666;font-weight: bold;margin-top: 10px;">已登录：
                            {{ userName }}
                        </div>
                    </div>
                    <div
                        style="height: 80px;display: flex;align-items: center;justify-content: space-around;width: 100%;background-color: #f1f7ff;border-bottom-right-radius: 5px;border-bottom-left-radius: 5px;">
                        <div style="display: flex;flex-direction: column;align-items: center;cursor: pointer;flex: 1;">
                            <img src="../../assets/game-web.png" style="width: 25px;height: 25px;">
                            <span
                                style="font-size: 13px;font-weight: bold;color: #888888;margin-top: 5px;">我的游戏</span>
                        </div>
                        <div style="display: flex;flex-direction: column;align-items: center;cursor: pointer;flex: 1;">
                            <img src="../../assets/logout-web.png" style="width: 23px;height: 23px;">
                            <span style="font-size: 13px;font-weight: bold;color: #888888;margin-top: 5px;">退出</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="false">
            <search-game-list ref="child" :onParentClick="hanleParentClick"></search-game-list>
        </div>

        <div v-if="showMask" @click="hideMaskAndOther" class="mask"></div>
        <div ref="loginView" class="login-view" v-if="showLogin">

            <div style="height: 60px; display: flex;align-items: flex-end;font-weight: bold;color: #888888;padding: 0 25px;box-shadow: -4px 0 8px rgba(0, 0, 0, 0.2);">
                <div @click="loginActive = 1" style="flex: 1;cursor: pointer;display: flex;flex-direction: column;align-items: center">
                    <span :style="loginActive === 1 && 'color: #f16868'">登录</span>
                    <div style="width: 30px;height: 4px;margin-top: 10px;" :style="loginActive === 1 && 'background-color: #f16868;'"></div>
                </div>

                <div @click="loginActive = 2" style="flex: 1;cursor: pointer;display: flex;flex-direction: column;align-items: center">
                    <span :style="loginActive === 2 && 'color: #f16868'">注册</span>
                    <div style="width: 30px;height: 4px;margin-top: 10px;" :style="loginActive === 2 && 'background-color: #f16868;'"></div>
                </div>
            </div>

            <img :src="logoUrl" style="width: 70px;height: 70px;margin-top: 20px;">

            <div style="padding: 10px 25px;">
                <span class="ds-label" style="margin-top: 2px;font-weight: bold">E兔玩账号</span>
                <van-field label-width="0" label="" style="background-color: #f7f9fd;border-radius: 8px;margin-top: 10px;"
                           placeholder="请输入E兔玩账号" v-model="account" clearable>
                </van-field>

                <span class="ds-label" style="margin-top: 20px;font-weight: bold">密码</span>
                <van-field label-width="0" type="password" label="" style="background-color: #f7f9fd;border-radius: 8px;margin-top: 10px;"
                           placeholder="请输入登录密码"  v-model="password" clearable/>

            </div>


        </div>

    </div>
</template>


<script>
import {request} from '@/api/request'
import SearchGameList from '../../views/home/SearchGameList.vue';

export default {
    components: {SearchGameList},
    name: "HomeNav",
    data() {
        return {
            index: 0,
            preIndex: '',
            game_name: '',
            dialogHover: false,
            dialogGame: false,
            userName: '',
            gameList: [],
            showMask: false,
            showLogin: false,
            account: '',
            password: '',
            loginActive: 1,

            logoUrl: '',
            webName: '',
        }
    },
    mounted() {
        this.initActiveMenuIndex();
        this.queryWebConfig();
        this.preIndex = localStorage.getItem('preIndexMenu')
    },
    methods: {
        hanleParentClick(message) {
            console.log(message)
        },
        hideMaskAndOther() {
            this.showMask = false;
            this.dialogGame = false;
            this.dialogHover = false;
            this.showLogin = false;
        },
        queryGame() {
            let options = {
                url: "/web/myGameList",
                data: {
                    params: {}
                }
            };
            request(options).then(res => {
                if (res.data.success) {
                    this.gameList = res.data.data.gameBaseInfoList;
                    this.userName = res.data.data.userName;
                } else {
                    Toast(res.data.error.message);
                }
            });
        },
        openLoginPage() {
            this.dialogHover = false;
            this.showLogin = true;
            this.showMask = true;
            this.$nextTick(()=> {
                if (window.WheelEvent) {
                    this.$refs.loginView.addEventListener('wheel', function(event) {
                        event.preventDefault();
                    }, { passive: false });
                }
            })
            //this.$router.push('/login');
        },
        queryWebConfig() {
            window.addEventListener('beforeunload', () => localStorage.removeItem('config'));
            let config = JSON.parse(localStorage.getItem('config'));
            if(config){
              this.webName = config.appName;
              this.logoUrl = config.logoUrl;
              return;
            }
    
            request({
                url:"/open/websiteConfig",
                method: 'post'
            }).then(res => {
                this.config = res.data.data;
                this.webName = config.appName;
                this.logoUrl = config.logoUrl;
                localStorage.setItem('config', JSON.stringify(res.data.data))
                localStorage.setItem('web_name', res.data.data.appName)
                localStorage.setItem('logo_url', res.data.data.logoUrl)
            })
        },
        toRegisterPage() {
            this.dialogHover = false;
            //this.$router.push('/register');
        },
        exit() {
            this.dialogGame = false;
            this.dialogHover = true;
            localStorage.removeItem('token');
        },
        hoverIn() {
            if (this.showLogin) return;
            let token = localStorage.getItem("token");
            /*this.dialogGame = true;
            return;*/
            this.showMask = true;
            if (token) {
                this.dialogGame = true;
                //this.queryGame();
            } else {
                this.dialogHover = true;
            }
        },
        initActiveMenuIndex() {
            let path = this.$route.path;
            if (path === '/index') {
                this.index = 1;
            } else if (path === '/home/gameList') {
                this.index = 2;
            } else if (path === '/home/fuli') {
                this.index = 3;
            } else if (path === '/home/kf') {
                this.index = 4;
            }
        },
        toIndexPage() {
            localStorage.setItem('preIndexMenu', this.index)
            this.$router.push('/index');
        },
        toGameListPage() {
            localStorage.setItem('preIndexMenu', this.index)
            this.$router.push('/home/gameList');
        },
        toKfPage() {
            localStorage.setItem('preIndexMenu', this.index)
            this.$router.push('/home/kf');
        },
        toFuliPage() {
            localStorage.setItem('preIndexMenu', this.index)
            this.$router.push('/home/fuli');
        },
        searchGame() {
            if (this.$route.path === '/home/searchGameList') {
                this.$refs.child.hanleParentClick(this.game_name);
                return;
            }

            this.$router.push({path: '/home/searchGameList', query: {gameName: this.game_name}});
        }
    }
}
</script>

<style scoped>
.ds-label {
    display: inline-block;
    width: 100%;
    text-align: left;
    margin: 5px 4px 0;
    font-size: 14px;
}
.login-view {
    width: 350px;
    height: 450px;
    background-color: #ffffff;
    border-radius: 12px;
    z-index: 1002;
    position: relative;
    margin: 50px auto 0;
    overflow: hidden;
}

.mask {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100vw;
    z-index: 1000;
    position: absolute;
}

.nav-center {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.logo-view {
    height: 80px;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #FFFFFF;
}

.menu-view {
    display: flex;
    margin-left: 30px;
}

.menu {
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
    user-select: none;
    position: relative;
}

.menu-active {
    color: #f16868;
}

.active-line {
    width: calc(100% - 40px);
    height: 3px;
    background-color: #f16868;
    position: absolute;
    left: 20px;
    bottom: 0;

}

.from-right {
    animation: right_to_left .3s linear;
}

.from-left {
    animation: left_to_right .3s linear;
}

@keyframes left_to_right {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes right_to_left {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

.search-view {
    height: 40px;
    width: 220px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 40px;
    padding: 0 5px 0 10px;
}

.search-input {
    background: transparent;
    outline: none;
    border: none;
    height: 30px;
    margin-left: 10px;
    width: 120px;
}

.search-button {
    height: 30px;
    width: 60px;
    background-color: #4a8fff;
    color: #ffffff;
    border-radius: 30px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 900px) {
    .nav-center {
        margin-left: 20px;
    }
}

.search {
    margin-left: 50px;
}

.search-box {
    position: relative;
}

.search-box img {
    cursor: pointer;
    height: 20px;
    left: 12px;
    top: 6px;
    position: absolute;
    align-items: center;
    vertical-align: middle;
}

.split {
    background-color: #e9ebee;
    height: 20px;
    left: 40px;
    top: 6px;
    position: absolute;
    width: 2px;
    unicode-bidi: isolate;
}

.search-box input {
    border: 1px solid #ccc;
    height: 30px;
    border: none;
    border-radius: 20px;
    font-size: 16px;
    color: #272b37;
    text-indent: 3em;
    font-family: 'Microsoft YaHei';
    font-weight: normal;
    width: 220px;
}

.btn {
    background: linear-gradient(90deg, #1c70ff, #28aafb);
    border-radius: 15px;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-size: 16px;
    justify-content: center;
    line-height: 1;
    position: absolute;
    right: 1px;
    top: 1px;
    height: 30px;
    width: 50px;
    align-items: center;
}

.icons-container {
    margin-left: 20px;
}

.user {
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.icon-container {
    margin-left: 20px;
    position: relative;
    display: inline-block;
}

.icon {
    width: 50px; /* 假设图标宽度为50px */
    height: auto;
    cursor: pointer; /* 鼠标悬停时显示指针 */
}

.tooltip {
    position: absolute;
    bottom: 100%; /* 提示框在图标下方 */
    left: 50%;
    transform: translateX(-50%); /* 居中提示框 */
    background-color: black;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    visibility: hidden; /* 初始不可见 */
    opacity: 0;
    transition: opacity 0.5s, visibility 0.5s;
    z-index: 1; /* 确保提示框在上方 */
}

.icon-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

.tool span {
    margin-left: 20px
}

.footer-list {
    max-width: 1080px;
    height: 60px;
    margin: auto;
}

.footer-list ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    text-align: center;
}

.footer-list ul i {
    font-size: 20px;
}

.game-item-view {
    width: 430px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 20px 25px;
    box-sizing: border-box;
    overflow: hidden;
}

.game-item-view:hover .game-name-one-line {
    color: #0e7dde;
}

.game-item-view:hover {
    transform: translateY(-3px);
}

.game-name-one-line {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    color: #333333
}

.game-tag-view {
    display: flex;
    color: #f15858;
    font-size: 12px;
    white-space: nowrap;
}

.game-tag-view span {
    margin-right: 10px;
    background-color: rgba(241, 88, 88, 0.1);
    padding: 2px 4px;
    border-radius: 2px;
}

.drop-down {
    position: absolute;
    top: 60px;
    right: -40px;
    background-color: white;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 30px 0 10px;
    z-index: 1002;
    width: 300px;
    height: 200px;
    box-sizing: border-box;
}
</style>