<template>
    <div class="login-page">
        <div v-if="showBack" style="text-align: left;width: 100%;position: absolute;top: 40px;padding-left: 20px;">
            <van-icon name="arrow-left" size="25" @click="back"/>
        </div>

        <div style="width: 100%;text-align: left;box-sizing: border-box;padding: 120px 25px 0;">
            <div style="display: flex;align-items: center">
<!--                <img src="../assets/logo.png" style="width: 40px;height: 40px;">-->
                <img :src="this.logoUrl" style="width: 40px;height: 40px;">
                <span style="font-size: 28px;font-weight: bold;margin-left: 15px;">{{ this.webName }}</span>
            </div>
            <div style="font-size: 16px;color: #666666; text-align: left;margin-top: 8px;">
                <span>欢迎登录</span>
            </div>
        </div>

        <div class="main-border">

            <van-form @submit="onSubmit" ref="submitForm">

                <span class="ds-label" style="margin-top: 20px;">账号</span>
                <van-field label-width="0" label="" style="color: #F18F25" :placeholder="`请输入${ this.webName }账号`"
                           class="border-input" v-model="account" clearable>
                    <template #button>
                        <van-icon name="arrow-down" @click="selectOtherAccount"/>
                    </template>
                </van-field>

                <span class="ds-label" style="margin-top: 20px;">密码</span>
                <van-field label-width="0" type="password" label="" style="color: #F18F25;"
                           placeholder="请输入登录密码" class="border-input" v-model="password" clearable/>

                <van-popup v-model="showPicker" round position="bottom">
                    <van-picker
                            show-toolbar
                            :columns="accountColumns"
                            @cancel="showPicker = false"
                            @confirm="pickAccount"
                            @change="onChange"
                    />
                </van-popup>

                <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 20px;font-size: 14px;padding: 0 5px;">

                    <van-checkbox v-model="rememberPassword" icon-size="14px" shape="square" checked-color="#e73d50">
                        <span style="color: #666666;">记住密码</span>
                    </van-checkbox>

                    <span @click="toForgetPage" style="color: #e73d50;">忘记密码?</span>
                </div>

                <div class="box-button">
                    <van-button type="info" block round native-type="submit" :loading="loading" style="height: 40px;margin-top: 30px;font-size: 14px;"
                                loading-text="登录中"
                                color="#e73d50">
                        登 录
                    </van-button>
                    <div class="text-button">还没有账号？<span @click="toRegisterPage" style="color: #e73d50">立即注册</span>
                    </div>
                </div>

                <div style="position: absolute;bottom: 20px;left: 0; width: 100%;display: flex;justify-content: center;box-sizing: border-box" v-if="showSpeedCheckbox">
                    <van-checkbox v-model="openSpeed" shape="square" icon-size="14px" checked-color="#e73d50" >
                        <span style="color: #888888;font-size: 13px;">我要加速</span>
                    </van-checkbox>
                </div>

            </van-form>

        </div>
    </div>
</template>

<script>

    import {login} from '@/api/request'
    import crypto from '@/utils/crypto.js';
    import logo from "@/assets/logo.png";
    import accountIcon from "@/assets/account.png";
    import passwordIcon from "@/assets/password.png";

    import {Toast} from 'vant'

    export default {
        name: 'login',
        data() {
            return {
                logo,
                accountIcon,
                passwordIcon,
                account: '',
                password: '',
                rememberPassword: false,
                accountPattern: /^[a-zA-Z0-9_]{6,12}$/,
                passwordPattern: /^[a-zA-Z0-9~!@#$%^&*()_+.]{6,20}$/,
                loading: false,
                channelId: '',
                gameId: '',
                accountColumns: [],
                showPicker: false,
                userList: [],
                currentIndex: 0,
                showBack: false,
                from: '',
                openSpeed: false,
                showSpeedCheckbox: false,
                webName: '',
                logoUrl: '',
            };
        },
        mounted() {
            this.channelId = localStorage.getItem('channelId');
            this.gameId = localStorage.getItem('gameId');
            this.webName = localStorage.getItem('web_name');
            this.logoUrl = localStorage.getItem('logo_url');

            let isRemember = localStorage.getItem('rememberPassword');
            if (isRemember === "true") {
                this.rememberPassword = true;
                this.account = localStorage.getItem('username');
                this.password = crypto.decrypt(localStorage.getItem('password'));
            }
            let from = localStorage.getItem('from');

            //需要开启加速的游戏id
            if(this.gameId === '1') {
                //排除安卓端
                if (from !== 'android_lmf_speedOn') {
                    this.showSpeedCheckbox = true;
                }
            }

            this.userList = JSON.parse(localStorage.getItem('userList')) || [];
            if (this.userList.length > 0) {
                this.userList.forEach((v, i) => {
                    this.accountColumns.push(v.u);
                })
            }
        },
        methods: {
            back() {
                this.$router.back();
            },
            onSubmit() {
                let ok = this.checkForm();
                if (ok !== 'ok') {
                    Toast(ok);
                    return;
                }
                if (this.openSpeed) {
                    localStorage.setItem('from', 'web_speedOn');
                }

                this.loading = true;
                let selectSpeed = this.openSpeed ? "1" : "0";
                let params = {
                    channelId: this.channelId,
                    device: 'H5',
                    gameId: this.gameId,
                    userName: this.account,
                    password: crypto.encrypt(this.password),
                    selectSpeed: selectSpeed
                };
                login(params).then(res => {
                    this.loading = false;
                    if (res.data.success) {
                        localStorage.setItem('token', res.data.data.token);
                        localStorage.setItem('user', JSON.stringify(res.data.data));
                        localStorage.setItem('loginUrl', res.data.data.loginUrl);

                        this.handlerRememberPassword();
                        Toast('登录成功');
                        //this.$router.replace({ path: `/play/${this.channelId}/${this.gameId}`});
                        //this.$router.push('/index');
                        this.$parent.enterGame();
                    } else {
                        Toast.fail(res.data.error.message);
                    }
                });
            },
            checkForm() {
                let ok = 'ok';
                if (!this.account) {
                    ok = "请输入"+ this.webName +"账号"
                } else if (!this.password) {
                    ok = "请输入密码";
                } else if (!this.accountPattern.test(this.account)) {
                    ok = this.webName + "账号为6-12位数字或字母";
                } else if (!this.passwordPattern.test(this.password)) {
                    ok = "密码为6-20位数字字母特殊符号";
                }
                return ok;
            },
            selectOtherAccount() {
                if (this.accountColumns.length > 0) {
                    this.showPicker = true;
                } else {
                    Toast('当前没有其他账号');
                }

            },
            pickAccount() {
                this.showPicker = false;
                let user = this.userList[this.currentIndex];
                this.account = user.u;
                this.password = crypto.decrypt(user.p);
            },
            onChange(picker, value, index) {
                this.currentIndex = index;
            },
            toForgetPage() {
                this.$router.push({path: '/reset'});
            },
            toRegisterPage() {
                this.$router.push({path: '/register'});
            },
            handlerRememberPassword() {
                localStorage.setItem('rememberPassword', this.rememberPassword);

                this.userList = JSON.parse(localStorage.getItem('userList')) || [];
                let has = false;
                let index = -1;
                this.userList.forEach((v, i) => {
                    if (v.u === this.account) {
                        has = true;
                        index = i;
                    }
                })

                let password = crypto.encrypt(this.password);
                localStorage.setItem('username', this.account);
                localStorage.setItem('password', password);

                if (this.rememberPassword) {
                    if (!has) {
                        let item = {
                            u: this.account,
                            p: password,
                        }
                        this.userList.push(item);
                        localStorage.setItem('userList', JSON.stringify(this.userList));
                    }
                } else {
                    if (has) {
                        this.userList.splice(index, 1);
                        localStorage.setItem('userList', JSON.stringify(this.userList));
                    }
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .login-page {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        height: 100vh;
        overflow: hidden;
        background-image: url(../assets/ds-m-bg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .main-border {
        max-width: 500px;
        text-align: center;
        background-color: white;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        width: 100%;
        height: 100%;
        border: 1px solid #eceaea;
        padding: 20px 30px;
        box-sizing: border-box;
        margin-top: 30px;
    }

    .select-other {
        margin-top: 10px;
        display: block;
        font-size: 14px;
        color: #b3b3b6;
    }

    .width-line {
        height: 1px;
        margin: 0 1.04rem;
        border-bottom: 1px solid #b3b3b6;
    }

    .forget-row {
        margin-top: 1.5rem;
        font-size: .8rem;
    }

    .box-button {
        margin: 20px 3px 0;

    }

    .ds-label {
        display: inline-block;
        width: 100%;
        text-align: left;
        margin: 5px 4px 0;
        font-size: 15px;
    }

    .text-button {
        font-size: 14px;
        margin-top: 20px;
        color: #888888;
        text-align: center;
    }

    .van-cell{
        padding: 8px 4px;
    }
    .van-field {
        border-bottom: 1px solid #dddddd;
    }

</style>
