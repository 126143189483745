<template>
    <div class="register-page">

        <div style="width: 100%;text-align: left;box-sizing: border-box;padding: 120px 25px 0;">
            <div style="display: flex;align-items: center">
                <img :src="logoUrl" style="width: 40px;height: 40px;">
                <span style="font-size: 28px;font-weight: bold;margin-left: 15px;">{{ this.webName }}</span>
            </div>
            <div style="font-size: 16px;color: #666666; text-align: left;margin-top: 8px;">
                <span>账号注册</span>
            </div>
        </div>

        <div class="main-border">
            <van-form @submit="onSubmit" ref="submitForm">
                <span class="ds-label">账号</span>
                <van-field label-width="0" label=""  placeholder="请输入账号" v-model="account" clearable/>
                <span class="ds-label" style="margin-top: 15px;">登录密码</span>
                <van-field label-width="0" type="password" label=""  placeholder="请输入登录密码"  v-model="password" clearable/>
                <span class="ds-label" style="margin-top: 15px;">确认密码</span>
                <van-field label-width="0" type="password" label="" placeholder="请输入登录密码"  v-model="confirmPassword" clearable/>

                <div class="box-button">
                    <div class="read-div">
                        <van-checkbox v-model="isRead" icon-size="14px" shape="square" checked-color="#e73d50">
                            <p class="read-line">我已阅读并同意<span @click="readDialogShow = true" class="text-button">《{{ this.webName }}用户协议》</span></p>
                        </van-checkbox>

                    </div>

                    <van-button type="info" block round native-type="submit" :loading="loading" style="height: 40px;margin-top: 20px;font-size: 14px;"
                                loading-text="正在注册"
                                color="#e73d50">
                        注   册
                    </van-button>

                    <div style="font-size: 14px;margin-top: 20px;padding: 0 3px;user-select: none">
                        <div>
                            <span style="color: #888888">已有账号？</span>
                            <span @click="toLoginPage" style="color: #e73d50;cursor: pointer">立即登录</span>
                        </div>
                    </div>

                </div>
            </van-form>

            <van-dialog  class="main-dialog" v-model="readDialogShow" >
                <iframe v-show="true" class="iframe-box" :src="readUrl" frameborder="0" scrolling="y"
                        width="100%" height="100%" style="min-height: 20rem"></iframe>
            </van-dialog>

        </div>
    </div>
</template>

<script>

    import {register} from '@/api/request'
    import crypto from '@/utils/crypto.js';
    import accountIcon from "@/assets/account.png";
    import passwordIcon from "@/assets/password.png";

    import { Dialog,Toast } from 'vant';

    export default {
        name: 'login',
        data() {
            return {
                accountIcon,
                passwordIcon,
                account: '',
                password: '',
                confirmPassword: '',
                accountPattern: /^[a-zA-Z0-9]{6,12}$/,
                passwordPattern: /^[a-zA-Z0-9~!@#$%^&*()_+]{6,20}$/,
                loading: false,
                channelId: '',
                gameId: '',
                isRead: false,
                readDialogShow: false,
                readUrl: 'https://game.etuw.cn/static/rule/policy.html',
                webName: '',
                logoUrl: '',
            };
        },
        mounted() {
            this.channelId = localStorage.getItem("channelId");
            this.gameId = localStorage.getItem("gameId");
            this.webName = localStorage.getItem("web_name");
            this.logoUrl = localStorage.getItem("logo_url");
        },
        methods: {
            onSubmit() {
                let ok = this.checkForm();
                if (ok !== 'ok') {
                    Toast(ok);
                    return ;
                }
                this.loading = true;
                let params = {
                    channelId: this.channelId ,
                    gameId: this.gameId ,
                    device: 'H5' ,
                    userName: this.account,
                    password: crypto.encrypt(this.password)
                };
                register(params).then(res => {
                    this.loading = false;
                    if(res.data.success) {
                        Dialog.alert({
                            message: '注册成功！',
                        }).then(() => {
                            this.toLoginPage();
                        });
                    }
                    else {
                        Dialog.alert({
                            message: res.data.error.message,
                        }).then(() => {

                        });
                    }
                });
            },
            checkForm() {
                if (!this.account) {
                    return "请输入" + this.webName + "账号";
                }
                if (!this.accountPattern.test(this.account)) {
                    return this.webName + "账号为6-12位数字字母";
                }
                if (!this.password) {
                    return "请输入密码";
                }
                if (!this.passwordPattern.test(this.password)) {
                    return "密码为6-20位数字字母特殊符号";
                }
                if (!this.confirmPassword) {
                    return "请再次输入密码";
                }
                if (this.password !== this.confirmPassword) {
                    return "两次密码输入不一致，请重新输入";
                }
                if (!this.isRead) {
                    return '账号注册需要同意' + this.webName + '用户协议';
                }
                return "ok";
            },

            toLoginPage() {
                this.$router.back();
            },

        }
    }
</script>

<style scoped>
    .register-page {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        height: 100vh;
        overflow: hidden;
        background-image: url(../../assets/ds-m-bg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .main-dialog {
    }
    .main-border {
        max-width: 500px;
        text-align: center;
        background-color: white;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        width: 100%;
        height: 100%;
        border: 1px solid #eceaea;
        padding: 20px 30px;
        box-sizing: border-box;
        margin-top: 30px;
    }

    .box-button {
        margin-top: 10px;
        font-size: 14px;
    }

    .read-div{
        display: flex;
    }

    /deep/html::-webkit-scrollbar, /deep/body::-webkit-scrollbar{width:0px;height:0px;}

    .text-button{
        color: #e73d50;
    }

    .van-checkbox{
        display: inline-flex;
    }
    .ds-label {
        display: inline-block;
        width: 100%;
        text-align: left;
        margin: 5px 4px 0;
        font-size: 15px;
    }
    .van-dialog{
        border-radius: 3px;
        padding: 10px 10px 0 10px;
    }
    .van-cell{
        padding: 8px 4px;
    }
    .van-field{
        /*background-color: #f8f8f8;*/
        border-bottom: 1px solid #dddddd;
    }
    /*.van-field:focus-within{
        outline: 2px #3478F6 solid;
    }
    /deep/.van-field__control {
        color: #3478F6;
    }*/
</style>
