<template>
  <div class="all-panel">
    <div class="header-panel">
      <div class="logo-panel">
          <van-image :src="logo" width="30px"></van-image>
          <span style="color: white;margin-left: 10px;font-size: 18px;font-weight: 700">金魂币自助充值</span>
      </div>
    </div>
    <div class="header-info">
      <p>充值信息</p>
    </div>
    <div class="main-page">
      <van-form ref="form" class="form-panel" validate-first @submit="onSubmit">

        <van-field v-model="account" label="账号" placeholder="请输入账号" autocomplete="off" required @blur="checkAccount"
             :rules="[{ required: true, message: ''},{ pattern : accountPattern, message: '账号为6-12位字母数字或下划线组成'}]"/>

        <van-field v-model="uid" label="UID" placeholder="请输入UID" autocomplete="off" required type="digit" @blur="checkUid"
              :rules="[{ required: true, message: ''}]"/>

        <van-field v-model="amount" type="number" required label="充值金额" placeholder="请输入充值金额"
             :rules="[{ required: true, message: '' },{ pattern: /^-?\d+([.]\d{1,2})?$/, message: '充值金额格式错误' }]"/>

        <van-field v-model="game_name" required right-icon="arrow" label="充值游戏" placeholder="请选择充值游戏"
             :rules="[{ required: true, message: ''}]" readonly @click="showPicker"/>

        <van-popup v-model="show" position="bottom" >
          <van-picker
                  title="请选择充值游戏"
                  show-toolbar
                  :columns="gameNameList"
                  @confirm="onConfirm"
                  @cancel="show = false"
          />
        </van-popup>
        <van-divider :style="{ color: '#888888', borderColor: '#888888', 'font-size': '.8rem', margin: '15px 16px' }">
          选择支付方式
        </van-divider>

        <div class="pay-class">
            <div @click="payType = '2'" style="display: flex;justify-content: space-between;align-items: center;padding: 0 15px;">
              <div style="display: flex;align-items: center">
                <img :src="zfbPayIcon" style="width: 20px;height: 20px"/>
                <span style="margin-left: 10px;font-size: 12px;font-weight: bold">支付宝</span>
              </div>
              <van-radio-group v-model="payType">
                  <van-radio name="2" shape="square" icon-size=".9rem"/>
              </van-radio-group>
            </div>
        </div>


        <div class="tips-panel">
          <van-icon name="warning" color="#049945">温馨提示</van-icon>
          <span>充值成功后将在该游戏中直接增加金魂币，虚拟产品，一经充值无法退还</span>
        </div>

        <div class="button-panel">
          <van-button block type="info" loading-text="充值中" :loading="submitting" native-type="submit">确认充值</van-button>
        </div>
      </van-form>

      <div class="diy-dialog" v-show="diyDialogShow">
        <div style="text-align: center">提示</div>
        <van-divider/>
        <div style="height: 80px">您将打开支付页面，请问是否继续？<br><br>
          <span style="color: #b3b3b6; font-size: 13px;">注：支付成功后请关闭新打开的支付窗口</span>
        </div>
        <van-divider/>
        <div class="diy-dialog-buttons">
          <div>
            <a @click="diyDialogShow = false">取消</a>
          </div>
          <div>
            <a target="_blank" :href="payUrl" @click="diyDialogShow = false">继续支付</a>
          </div>
        </div>
      </div>
    </div>

    <van-dialog class="pay-code-dialog" v-model="payCodeShow" confirm-button-text="支付完成点击关闭">
      <pay-code ref="payCode"></pay-code>
    </van-dialog>

    <van-overlay :show="diyDialogShow"/>
  </div>
</template>

<script>
  import { getPlayedGameList, qlOrder, get } from '@/api/request'
  import logo from '@/assets/logo.png'
  import wxPayIcon from '@/assets/pay_wx.png'
  import zfbPayIcon from '@/assets/pay_zfb.png'
  import payCode from "@/components/pay-code.vue";
  import { Toast } from 'vant'
  import '@vant/touch-emulator';

  export default {
    name: 'UserRecharge',
    components: {
      'pay-code': payCode,
    },
    data() {
      return {
        logo,
        zfbPayIcon,
        wxPayIcon,
        showAli: true,
        accountPattern: /^[a-zA-Z0-9_]{6,20}$/,
        submitting: false,
        gameList: [],
        gameNameList: [],
        show: false,
        payType: '',
        account: '',
        confirmAccount: '',
        game_name: '',
        uid: '',
        game_id: '',
        amount: '',
        diyDialogShow: false,
        payCodeShow: false,
        payUrl: '',

      }
    },
    mounted() {
        let ua = window.navigator.userAgent.toLowerCase();
        //通过正则表达式匹配ua中是否含有MicroMessenger字符串
        if(ua.match(/MicroMessenger/i) == 'micromessenger'){
          this.showAli=false;
        }
    },
    methods: {
      reset() {
        this.amount = '';
      },
      checkAccount() {
        this.queryGameList(1, this.account);
      },
      checkUid() {
        this.queryGameList(2, this.uid);
      },
      queryGameList(type, value) {
        if(!value) {
            return;
        }
        let url = "/open/checkAndGetPlayGame?checkType=" + type + "&checkValue=" + value;
        get(url).then(res => {
          if (res.data.success) {
            if (type === 1) {
              let uid = res.data.data.userId + "";
              if (this.uid !== uid) {
                this.uid = uid;
                this.gameList = res.data.data.gameList;
                this.makeData();
              }
            }
            else {
              let account = res.data.data.userName;
              if (this.account !== account) {
                this.account = account;
                this.gameList = res.data.data.gameList;
                this.makeData();
              }
            }

          } else {
            if (type === 1) {
               this.uid = '';
            }
            else {
              this.account = '';
            }
            Toast(res.data.error.message);
            this.gameList = [];
            this.game_name = '';
          }
        })
      },
      makeData() {
        let first = this.gameList[0];
        this.game_name = first.game_name;
        this.game_id = first.game_id;

        this.gameNameList = [];
        this.gameList.forEach((e, i) => {
          this.gameNameList.push(e.game_name);
        });
      },
      onSubmit() {
        if (!this.account || !this.uid) {
          Toast('请先完善账号信息');
          return ;
        }
        if (this.amount < 1) {
          Toast('充值金额不能小于1');
          return ;
        }
        if (!this.game_id) {
          Toast('请选择充值游戏');
          return ;
        }
        if (!this.payType) {
          Toast('请选择支付方式');
          return ;
        }
        this.submitting = true;
        let params = {
          userName: this.account,
          gameId: this.game_id,
          money: this.amount,
          paymentType: this.handPayType(this.payType)
        };
        qlOrder(params).then(res => {
          this.submitting = false;
          if (res.data.success) {
            console.log('支付成功');
            this.payUrl = res.data.data.payString;
            let orderId = res.data.data.orderId;
            this.openPayUrl(this.payUrl, orderId);
          }
          else {
            Toast(res.data.error);
          }
        });
      },
      openPayUrl(url, orderId) {
        if (this.isMobile()) {
          let ua = navigator.userAgent.toLowerCase();
          if (/iphone|ipad|ipod/.test(ua)) {//判断是否ios
            this.diyDialogShow = true;
          }else{
            window.open(url, '');
          }
        } else {
          this.payCodeShow = true;
          this.$nextTick(function () {
            let data = {
              payCode: url,
              payType: payType,
              payPrice: this.amount,
              payOriginalPrice: this.amount,
              orderId: orderId,
            }
            this.$refs.payCode.init(data);
          });
        }
      },
      onConfirm(value, index) {
        this.game_name = value;
        this.game_id = this.gameList[index].game_id;
        this.show = false;
      },
      showPicker() {
        if (!this.account || !this.uid) {
          Toast('请先完善账号信息');
          return ;
        }
        if (this.gameList.length === 0) {
          Toast('暂未查询到玩过的游戏');
          return;
        }
        this.show = true;
      },
      validateSameAccount() {
        return new Promise((resolve) => {
          if (!this.account || !this.confirmAccount) {
            resolve(true);
          }
          if (this.account !== this.confirmAccount) {
            resolve(false);
          }
          resolve(true);

        });
      },
      isMobile() {
        return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      },
      handPayType(payType) {
        let isMobile = this.isMobile();
        if (isMobile) {
          return payType;
        }
        if (payType === '1') {
          return '1'
        }
        if (payType === '2') {
          return '4'
        }
        if (payType === '3') {
          return '5'
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  .all-panel {
    width: 100%;
    background-color: #f2f2f2;
    overflow-y: auto;
    height: 100vh;
    .header-panel {
      width: 100%;
      height: 70px;
      background-color: #37414d;
      .logo-panel{
        padding: 0 20px;
        max-width: 600px;
        margin: 0 auto;
        display: flex;
        height: 100%;
        align-content: center;
        align-items: center;
      }
    }
    .header-info{
      max-width: 600px;
      margin: 0 auto;
      text-align: left;
      padding-left: 20px;
      font-weight: 600;
    }
    .main-page{
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      background-color: white;
      padding-bottom: 15px;
      .form-panel{
        .van-cell::after {
        }
        .pay-class{
          text-align: left;
          padding: 0 8px;
          .van-cell{
            padding: 3px 16px;
            .van-cell__title{
              font-size: .8rem;
              font-weight: 600;
            }
          }
        }
        .tips-panel{
          margin: 20px 15px 0 15px;
          padding: 8px 8px;
          text-align: left;
          background-color: #d4ecdc;
          font-size: 12px;
          color: #049945;
          line-height: 25px;
          span {
            display: block;
          }
        }
        .button-panel{
          max-width: 200px;
          margin-top: 20px;
          margin-left: 20px;
          font-weight: 700;
          span {
            font-size: 14px;
          }

        }
      }

      .diy-dialog {
        z-index: 9;
        top: 35%;
        background-color: white;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 8px 1px 8px;
        text-align: left;
        font-size: 14px;
        width: 70%;
        border-radius: 10px;
        left: 14.5%;
        .diy-dialog-buttons {
          padding: 0 15px;
          font-size: 14px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 0;
          height: 30px;
          a {
            color: #1783d6;
          }
          div {
            width: 100%;
            text-align: center;
          }
        }
        .van-divider--hairline {
          margin: 8px 0;
        }
      }
    }
  }
</style>
