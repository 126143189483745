<template>
    <div class="m-view m-main" style="height: 250px;background-color: #3b4154;width: 100%;display: flex;flex-direction: column;align-items: center;color: #FFFFFF">
        <div style="display: flex;align-items: center;margin-top: 30px;" class="no-select" v-if="false">
           <!-- <span>关于我们</span>
            <div class="column-line"></div>-->
            <span @click="openPolicyPage">隐私政策</span>
            <div class="column-line"></div>
            <span @click="openAgreementPage">用户协议</span>
            <!-- <div class="column-line"></div>
            <span>平台公告</span>-->
        </div>

        <div class="m-view m-font" style="color: #aaaaaa;font-size: 14px;margin-top: 20px;display: flex;flex-direction: column">
            <span>{{ config.copyright }}</span>
            <span class="info-text">{{ config.companyname }}</span>
            <span class="info-text">联系地址：{{ config.address }}</span>
            <span class="info-text"><a style="color: #aaaaaa;cursor:pointer;" href="https://beian.miit.gov.cn"> {{ config.icp }} </a></span>
            <!--<div style="display: flex;justify-content: center;margin-top: 15px;">
                <img src="../assets/gonganbu.png" style="width: 20px;">
                <a style="color: #aaaaaa;cursor:pointer;margin-left: 5px;" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=">X公网安备 XXXXXXXXXXXXX号</a>
            </div>-->
            <div  class="info-text" style="display: flex;flex-wrap: wrap">
                <span>抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。</span>
                <span>适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。</span>
            </div>

        </div>



    </div>
</template>

<script>
    import {request} from "@/api/request";

    export default {
        name: "HomeFooter",
        data() {
            return {
                config: {
                  icp: '蒙ICP备2021003855号-1',
                  gongan: '',
                  copyright: 'copyright © 2024 etuw.cn/ All Rights Resered.',
                  address: '内蒙古自治区呼和浩特市和林格尔县云计算大数据创客中心三号楼207室、210室',
                  companyname: '内蒙古沃扬网络科技有限公司',
                  policy: 'https://game.etuw.cn/static/rule/policy.html',
                  agreement: 'https://game.etuw.cn/static/rule/agreement.html'
                },
            }
        },
        mounted() {
            this.queryWebConfig();
        },
        methods: {
            queryWebConfig() {
                window.addEventListener('beforeunload', () => localStorage.removeItem('config'));
                let config = JSON.parse(localStorage.getItem('config'));
                if(config){
                    this.config = config;
                    return;
                }
    
                request({
                    url:"/open/websiteConfig",
                    method: 'post'
                }).then(res => {
                    this.config = res.data.data;
                    localStorage.setItem('config', JSON.stringify(res.data.data))
                    localStorage.setItem('web_name', res.data.data.appName)
                    localStorage.setItem('logo_url', res.data.data.logoUrl)
                })
            },
            openPolicyPage() {
                window.open(this.policy, '');
            },
            openAgreementPage() {
                window.open(this.agreement, '');
            },
          }
    }
</script>

<style scoped>
    .column-line {
        display: inline-block;
        width: 1px;
        height: 15px;
        background-color: #ffffff;
        margin-left: 10px;
        margin-right: 10px;
    }
    .no-select {
        user-select: none;
        cursor: pointer;
    }
    .info-text {
        margin-top: 15px;
    }
    @media screen and (max-width: 900px) {
        .m-view {
            align-items: flex-start !important;
            text-align: left;
        }
        .info-text {
            margin-top: 8px;
        }
        .m-font {
            font-size: 12px !important;
        }
        .m-main {
            padding: 0 15px;
            box-sizing: border-box;
        }
    }

</style>