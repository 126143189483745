import Vue from 'vue'
import Router from 'vue-router'
import Register from './views/login/register.vue';
import Reset from './views/login/reset.vue';
import Main from './views/main/main.vue';
import SdkMain from './views/sdk/SdkMain';
import LoginKf from './views/login/kf.vue';
import Download from './views/home/GameDetail';
import IOSDemo from './download/iosdemo.vue';
import GameList from './views/home/GameList';
import Index from './views/home/Index';
import Fuli from './views/home/Fuli';
import Me from './views/home/Me';
import Kf from './views/home/Kf';
import SelfPay from './views/home/selfpay.vue';
import SelfPayWX from './views/home/selfpaywx.vue';
import QLWXJS from './views/home/wxjsapi.vue';
import GameDetail from './views/app/gamedetail.vue';
import Intro from './views/app/intro.vue';
import BindWx from './views/home/bindwx.vue';
import GameSpeedH5 from './views/home/GameSpeedH5';
import SearchGameList from './views/home/SearchGameList';
import TaoHuaLiGame from './views/dispatch/taohuali.vue';

import Test from "./views/test/game";

Vue.use(Router)

export default new Router({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/index'
    },
    {
      path: '/index',
      name: 'Index',
      component: Index
    },
    {
      path: '/home/gameList',
      name: 'GameList',
      component: GameList
    },
    {
      path: '/home/kf',
      name: 'Kf',
      component: Kf
    },
    {
      path: '/home/fuli',
      name: 'Fuli',
      component: Fuli
    },
    {
      path: '/home/me',
      name: 'Me',
      component: Me
    },
    {
      path: '/test',
      name: 'Test',
      component: Test
    },
    {
      path: '/sdkh5',
      name: 'SdkMain',
      component: SdkMain
    },
    {
      path: '/h5/*/*',
      name: 'Main',
      component: Main
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    },
    {
      path: '/reset',
      name: 'Reset',
      component: Reset
    },
    {
      path: '/selfPay',
      name: 'SelfPay',
      component: SelfPay
    },
    {
      path: '/jiabi',
      name: 'SelfPay',
      component: SelfPay
    },
    {
      path: '/selfPayWX',
      name: 'SelfPayWX',
      component: SelfPayWX
    },
    {
      path: '/loginKf',
      name: 'LoginKf',
      component: LoginKf
    },
    {
      path: '/d/*/*',
      name: 'Download',
      component: Download
    },
    {
      path: '/download/*/*',
      name: 'Download',
      component: Download
    },
    {
      path: '/download/iosGuide',
      name: 'IOSDemo',
      component: IOSDemo,
      meta: {
        title: 'IOS信任指引'
      }
    },
    {
      path: '/qlwxjs',
      name: 'QLWXJS',
      component: QLWXJS,
      meta: {
        title: '微信支付'
      }
    },
    {
      path: '/app/GameDetail',
      name: 'GameDetail',
      component: GameDetail
    },
    {
      path: '/app/intro',
      name: 'Intro',
      component: Intro
    },
    {
      path: '/bindWx',
      name: 'BindWx',
      component: BindWx
    },
    {
      path: '/gameSpeedH5',
      name: 'GameSpeedH5',
      component: GameSpeedH5
    },
    {
      path: '/home/searchGameList',
      name: 'SearchGameList',
      component: SearchGameList
    },
    {
      path: '/h5game/taohuali',
      name: 'TaoHuaLiGame',
      component: TaoHuaLiGame
    },

  ]
})
